<template lang="">
  <div>
    <b-card>
      <basetable
        ref="basetable"
        :dataurl="dataurl+'/'+this.$route.params.id+'/file'"
        :fields="fields"
        :baseroute="baseroute"
        :is-modal="true"
        :is-add="false"
        :is-edit="false"
        :is-filter="false"
        :is-hover="true"
        @onRowSelected="onRowSelected"
      >
        <template v-slot:buttonaddon>
          <b-button
            v-b-modal="'modal-select-doc'"
            class="mr-1"
            size="sm"
            variant="success"
          >
            Upload Dokumen Baru
          </b-button>
        </template>
        <template #cell(name)="data">
          <span style="color: blue;">
            {{ data.item.name }}
          </span>
        </template>
        <template #cell(_)="data">
          <div>
            <b-button
              size="sm"
              variant="danger"
              class="mr-1 btn-icon"
              @click="confirmDelete(data)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </template>
      </basetable>
      <b-modal
        :id="'modal-select-doc'"
        centered
        title="Upload File"
        hide-footer
      >
        <div>
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col cols="12">
                  <form-file
                    v-model="file"
                    label="Judul File"
                  />
                  <form-v-select
                    v-model="file_type"
                    :selected="file_type"
                    rules="required"
                    label="Tipe File"
                    placeholder="Select Value"
                    :options="optionFileType"
                  />
                </b-col>
                <b-col cols="12">
                  <hr>
                  <b-form-group
                    label-cols-md="3"
                    label=""
                  >
                    <b-button
                      style="float: right;"
                      class="mr-1"
                      size="sm"
                      variant="primary"
                      type="button"
                      @click.prevent="validationForm"
                    >
                      <span v-if="loading">
                        <b-spinner small />
                        Loading...
                      </span>
                      <span v-else>Upload</span>
                    </b-button>
                    <b-button
                      style="float: right;"
                      class="mr-1"
                      size="sm"
                      variant="warning"
                      type="button"
                      @click.prevent="hideForm"
                    >
                      <span>Batal</span>
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </div>
      </b-modal>
    </b-card>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import basetable from '@/views/base/BaseTable.vue'
import config from './Config.js'
import {BCard,BBadge,BButton,BModal,BRow, BCol, BForm, BSpinner, BFormGroup} from 'bootstrap-vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import FormFile from '@/views/base/form/FormFile.vue'

export default {
  components: {
    ValidationObserver,
    basetable,
    BCard,
    BBadge,
    BModal,
    BButton,
    BRow,
    BCol,
    BForm,
    BSpinner,
    FormVSelect,
    FormFile,
    BFormGroup
  },
  data() {
    return {
      title:config.title,
      dataurl:config.dataulr,
      baseroute:config.baseroute,
      fields:config.fields,
      optionFileType:[],
      file_type:null,
      file:null,
      loading:false
    }
  },
  mounted(){
    this.fetchDataType()
  },
  methods:{
    fetchDataType(){
      this.$http.get('file-type?filter[all_invoice]=true').then(res=>{
        this.optionFileType = res.data.data
      })
    },
    hideForm(){
      this.$bvModal.hide('modal-select-doc')
      this.$refs.simpleRules.reset()
    },
    validationForm(){
      this.$refs.simpleRules.validate().then( success => {
        if(success){
          this.loading = true
          let formData = new FormData()
          let filename = ""
          formData.append('file', this.file)
          this.$http.post('upload/file', formData, {headers: {'Content-Type': 'multipart/form-data'}})
          .then(res=>{
            if(res.data){
              if(res.data.path.split("/"))
                filename = res.data.path.split("/").pop()
              
              let params = {
                name: filename,
                file_type_id: this.file_type.id,
                file: res.data.path
              }
              this.$http.post(this.dataurl+'/'+this.$route.params.id+'/file', params)
              .then(()=>{
                this.successSubmit()
              })
              .catch(err=>{
                this.errorSubmit(err)
              })
              .finally(() => {this.loading = false})
            }else{
              return this.$bvToast.toast('Gagal Upload File', {
                title: 'Error',
                solid: true,
                variant:'danger'
              })
            }
          })
          .catch(err=>{
            this.errorSubmit(err)
          })
          .finally(() => {this.loading = false})
        }else{
          return this.$bvToast.toast('Please correct form input before submit', {
            title: 'Form Error',
            solid: true,
            variant:'danger'
          })
        }
      })
    },
    successSubmit(){
      this.$refs.basetable.fetchData()
      this.hideForm()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
    confirmDelete(data){
      const {id} = data.item
      this.$bvModal
        .msgBoxConfirm('Are you sure delete '+Object.values(data.item)[1]+' ?', {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.delete(this.dataurl+'/file/'+id)
              .then(() => {
                this.$refs.basetable.fetchData()
            })
          }
        })
    },
    onRowSelected(val){
      window.open(val.url,'_blank').focus()
    }
  }
}
</script>
<style lang="">

</style>
