<template>
  <b-card>
    <base-info
      ref="baseinfo"
      :geturl="dataurl"
      :data-id="user_type=='vendor'?user.id:''"
      :fields-title="fieldsTitle"
      :fields-content="fields"
      :baseroute="baseroute"
      :isdelete="false"
      @readData="showData"
    >
      <template v-slot:buttonaction>
        <b-button
          v-if="user_type != 'vendor'"
          class="mr-1"
          size="sm"
          variant="danger"
          type="button"
          @click.prevent="confirmBlokir"
        >
          
          <span>Blokir Vendor</span>
        </b-button>
        <b-button
          v-if="approval_status == 0"
          class="mr-1"
          size="sm"
          variant="success"
          type="button"
          @click.prevent="confirmApproval(true)"
        >
          
          <span>Setujui</span>
        </b-button>
        <b-button
          v-if="approval_status == 0"
          class="mr-1"
          size="sm"
          variant="warning"
          type="button"
          @click.prevent="confirmApproval(false)"
        >
          
          <span>Tolak</span>
        </b-button>
      </template>
      <template v-slot:infodetail>
        <div class="d-flex justify-content-start">
          <b-avatar
            class="img-fluid rounded mr-1"
            rounded="sm"
            variant="light-primary"
            size="45"
          >
            <feather-icon
              size="21"
              icon="TagIcon"
            />
          </b-avatar>
          <div class="d-flex flex-column ms-1">
            <div class="user-info mb-1">
              <p
                style="margin: 0;"
                class="card-text mr-1"
              >
                <b v-if="model">{{ model.vehicle_count|numFormat }} Unit</b>
              </p>
              <p
                style="margin: 0;"
                class="card-text mr-1"
              >
                Kendaraan Aktif
              </p>
            </div>
          </div>
        </div>
      </template>
    </base-info>
  </b-card>
</template>

<script>

import {BCard, BButton, BAvatar} from 'bootstrap-vue'
import BaseInfo from '@/views/base/BaseInfoV2.vue'

export default {
  components: {
    BCard,
    BaseInfo,
    BButton,
    BAvatar
  },

  data() {
    return {
      user:null,
      user_type:null,
      dataurl:"/vendor",
      baseroute:"vendor",
      fieldsTitle: [
        { key: 'name', is_title:true},
        { key: 'code'},
        { key: 'approval_status', label: 'Status', type:'spankey', data:{0:'Pengajuan', 1:'Diterima', 2:'Ditolak'}},
      ],
      fields: [
        { key: 'address', label: 'Alamat', icon:'CompassIcon'},
        { key: 'phone', label: 'Handphone', icon:'PhoneIcon'},
        { key: 'email', label: 'Email', icon:'MailIcon'},
        { key: 'hr'},
        { key: 'cp_name', label: 'Contact Person', icon:'UserIcon'},
        { key: 'cp_number', label: 'No Hp CP', icon:'PhoneIcon'},
        { key: 'cp_email', label: 'Email CP', icon:'MailIcon'},
        { key: 'created_at', label: 'Tanggal Register', icon:'CalendarIcon', type:'date'},
        { key: 'updated_at', label: 'Persetujuan', icon:'CalendarIcon', type:'date'},
      ],
      approval_status : null,
      model:null
    }
  },
  created(){
    if(this.$route.meta.user){
      this.user_type = this.$route.meta.user.contact_type.split('\\').pop().toLowerCase()
      this.user = this.$route.meta.user.contact
      this.$route.params.id = this.user.id
    }
    if(this.$route.meta.baseroute)
        this.baseroute = this.$route.meta.baseroute
  },
  methods:{
    showData(result){
      this.$emit("readData", result)
      this.model = result
      this.approval_status = result.approval_status
    },
    confirmBlokir(){
      const id = this.$route.params.id
      this.$bvModal
        .msgBoxConfirm('Are you sure blokir vendor ?', {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.put(this.dataurl+'/'+id+'/active', {is_active: false})
              .then(() => {
                this.$router.push({ name: this.baseroute})
            })
          }
        })
    },
    confirmApproval(approve){
      const id = this.$route.params.id
      let params = {
        approval_status : 2,
        approval_note : 'ditolak'
      }

      if(approve){
        params.approval_status = 1
        params.approval_note = 'diterima'
      }

      this.$bvModal
        .msgBoxConfirm('Are you sure ?', {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.put(this.dataurl+'/'+id+'/approval', params)
              .then(() => {
                this.$refs.baseinfo.fetchData()
            })
          }
        })
    }
  }
}
</script>