<template>
  <b-card>
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="FileIcon" />
          Dokumen
        </template>
        <document />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="BookmarkIcon" />
          Wilayah Pengadaan
        </template>
        <spb />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UserIcon" />
          User
        </template>
        <user />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>

import {BCard,BTabs, BTab} from 'bootstrap-vue'
import Document from './document/Index.vue'
import Spb from './IndexSpb.vue'
import Assurance from './IndexAsurance.vue'
import Workshop from './IndexWorkshop.vue'
import User from './IndexUser.vue'
import Dashboard from './Dashboard.vue'

export default {
  components: {
    BCard,
    BTabs, BTab,
    Document,
    Spb,
    Assurance,
    Workshop,
    Dashboard,
    User
  },
}
</script>
