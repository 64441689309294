<template>
  <div>
    <b-row>
      <b-col cols="9">
        <info @readData="showData" />
      </b-col>
      <b-col cols="3">
        <b-card
          v-if="isUser"
          title="Akses Aplikasi"
        >
          <p style="margin-bottom: 5px;">
            Level : <span class="badge mr-1 bg-primary">{{ level }}</span>
          </p>
          <p style="margin-bottom: 5px;">
            Status : <span
              class="badge mr-1 "
              :class="status>0?'bg-primary':'bg-warning'"
            >{{ status>0?'Aktif':'Tidak Aktif' }}</span>
          </p>
          <p style="margin-bottom: 5px;">
            Login Terakhir : {{ last_login }}
          </p>
          <b-button
            class="mr-1 col-md-12"
            size="sm"
            :variant="status>0?'warning':'primary'"
            type="button"
            @click="confirmBlokir"
          >
            <span>{{ status>0?'Set Non Aktif Akun':'Set Aktif Akun' }}</span>
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <view-tab-vendor v-if="user_type == 'vendor'" />
        <view-tab v-else />
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {BRow, BCol, BCard, BButton} from 'bootstrap-vue'

import Info from './include/Info.vue'
import ViewTab from './include/ViewTab.vue'
import ViewTabVendor from './include/ViewTabVendor.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    Info,
    ViewTab,
    ViewTabVendor,
    BButton
  },
  data(){
    return{
      user_type:null,
      status:0,
      level:'-',
      last_login:'-',
      isUser:false,
      modelUser:null
    }
  },
  created(){
    if(this.$route.meta.user){
      this.user_type = this.$route.meta.user.contact_type.split('\\').pop().toLowerCase()
    }
  },
  methods:{
    showData(data){
      if(data.user){
        this.modelUser = data.user
        this.isUser = true
        this.level = data.user.role?data.user.role.name:'-'
        this.status = data.user.is_active
        this.last_login = data.user.last_login_at==null?'-':data.user.last_login_at
      }
    },
    confirmBlokir(){
      const id = this.modelUser.id
      this.$bvModal
        .msgBoxConfirm('Are you sure '+(this.status == 1?'non active':'active')+' user ?', {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.put('user/'+id+'/active', {is_active: this.status>0?false:true})
              .then(res => {
                alert(JSON.stringify(res))
            })
          }
        })
    },
  }
}
</script>
