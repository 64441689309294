<template lang="">
  <basetable
    ref="basetable"
    :dataurl="dataurl+'/'+this.$route.params.id+'/spb'"
    :fields="fields"
    :baseroute="baseroute"
    :is-edit="false"
    :is-delete="false"
    :is-add="false"
    :is-filter="false"
  >
    <template #cell(_)="data">
      <b-button
        size="sm"
        variant="primary"
        class="mr-1 btn-icon"
        @click="viewForm(data)"
      >
        <feather-icon icon="FolderIcon" />
      </b-button>
    </template>
    <template #cell(vehicles_count)="data">
      <span>{{ data.item.vehicles_count|numFormat }}</span>
    </template>
  </basetable>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import baseSelect from '@/views/base/BaseSelect.vue'
import { BCard,BButton,BFormGroup,BFormSelect,BRow,BCol } from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard,
    BButton,
    BFormGroup,
    baseSelect,
    BFormSelect,
    BRow,
    BCol
  },
  data() {
    return {
      dataurl:"/vendor",
      baseroute:"spb",
      fields: [
        { key: 'number', label: 'SPB', sortable: true },
        { key: 'region.name', label: 'Region', sortable: true },
        { key: 'year', label: 'Tahun Pengadaan', sortable: true },
        { key: 'vehicles_count', label: 'Jumlah Unit', type: 'number', sortable: true }
      ]
    }
  },
  methods:{
    viewForm(data){
      this.$router.push({ name: this.baseroute+'-detail',params : { id: data.item.id} })
    }
  }
}
</script>
<style lang="">

</style>
