<template>
  <div v-if="model">
    <div class="col-md-9 ml-1">
      <div class="row">
        <div class="col-lg-6 col-sm-6 col-12">
          <div class="card rounded">
            <div class="card-header">
              <div>
                <h2 class="mb-0">
                  <b>{{ model.spb.total ? model.spb.total : 0 }} SPB</b>
                </h2>
                <p class="card-text">
                  SPB Aktif : {{ model.spb.active ? model.spb.active : 0 }} Paket
                </p>
              </div>
              <div class="avatar bg-light-success p-50 m-0">
                <div class="avatar-content">
                  <feather-icon
                    icon="TruckIcon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-sm-6 col-12">
          <div class="card rounded">
            <div class="card-header">
              <div>
                <h2 class="mb-0">
                  <b>{{ model.vehicle.total ? model.vehicle.total : '0' }} Kendaraan</b>
                </h2>
                <p class="card-text">
                  Aktif : {{ model.vehicle.active ? model.vehicle.active : 0 }} Unit
                </p>
              </div>
              <div class="avatar bg-light-danger p-50 m-0">
                <div class="avatar-content">
                  <feather-icon
                    icon="ActivityIcon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg col-sm col-12 pl-0">
        <b-card
          class="card-transaction "
          no-body
        >
          <b-card-header>
            <b-card-title>Notifikasi</b-card-title>
          </b-card-header>

          <b-card-body
              style="position:relative; height:400px; overflow-y:scroll;"
          >
            <span v-if="list_notification.length == 0" class="ml-3">Nothing else</span>
            <div
              v-else
              v-for="(index,key) in list_notification"
              class="transaction-item"
            >
              <b-media no-body>
                <b-media-aside>
                  <b-avatar
                    rounded
                    size="42"
                    :variant="randomColor(warna)"
                  >
                    <feather-icon
                      size="18"
                      icon="PocketIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="transaction-title">
                    {{ index.data.title }}
                  </h6>
                  <small v-if="index.data.data.number">{{ index.data.data.number }}</small>
                </b-media-body>
              </b-media>
            </div>
          </b-card-body>

        </b-card>
      </div>
    </div>

       
  </div>
</template>

<script>
import CardBox from "./CardBox.vue"
import { BRow,BCard, BCol,BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, } from 'bootstrap-vue'

export default {
  components: {
    CardBox,
    BRow, BCol,BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
  },
  data(){
      return {
          model:null,
          list_notification:[],
          warna:['light-primary','light-success','light-info']
      }
  },
  mounted(){
      this.getData()
      this.getNotifikasi()
  },
  methods: {
      randomColor(item){
        return item[Math.floor(Math.random()*item.length)]
      },
      getData(){
          this.$http.get('vendor/'+this.$route.params.id+'/dashboard').then(res=>{
              this.model = res.data
          })
      },
	  getNotifikasi(){
		  this.$http.get('notification?length=').then(res=>{
          for (let notif of res.data.data){
            if (JSON.stringify(notif).includes(this.$route.params.id)){
              this.list_notification.push(notif);
            }
          }
      })
	  }
  }
}
</script>

<style lang="scss" scoped>

</style>